import { Stack } from "@mui/material";
import { FC } from "react";
import { RootOrderModel } from "../../../../application/repositories/assignment-of-vehicles-repository";
import { useAssignmentOfVehicles } from "../../state-management/assignment-of-vehicles-hook";
import { DropVehicleContainer } from "./drop-vehicle-container";
import { DropOrderContainer } from "./drop_order-container";

export const AffectedVehicles: FC<{ rootOrder: RootOrderModel }> = ({
  rootOrder,
}): JSX.Element => {
  const {
    affectedVehicles,
    getTotalPaxOrders,
    getTotalCapacityVehicles,
    getNoAffectedPaxInTrip,
  } = useAssignmentOfVehicles();

  const currentAffectedVehicles = [...affectedVehicles].filter(
    (item) => item.trip === rootOrder.trip
  );

  const totalPaxOrders = getTotalPaxOrders(rootOrder);
  const totalCapacityVihicles = getTotalCapacityVehicles(
    currentAffectedVehicles
  );

  const noAffectedPaxInTri = getNoAffectedPaxInTrip(rootOrder.trip);

  const canDrop =
    totalPaxOrders > totalCapacityVihicles || noAffectedPaxInTri > 0;

  return (
    <Stack direction={"column"} spacing={2}>
      {canDrop && <DropVehicleContainer rootOrder={rootOrder} />}

      {currentAffectedVehicles.reverse().map((item, index) => (
        <DropOrderContainer
          key={index}
          vehicleContainer={item}
          noAffectedPaxInTri={noAffectedPaxInTri}
          type={rootOrder.type}
        />
      ))}
    </Stack>
  );
};
