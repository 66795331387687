import { VehicleTypeModel } from "../models/vehicle-model";
import httpClient from "../utils/http-client";

export const fetchAssignmentOfVehiclesData = async (params: {
  date: string;
  lineId: number[];
  h_start: string;
  h_end: string;
}): Promise<ResponseModel> => {
  const url = `trips/orders/ajax`;
  const response = await httpClient
    .post(url, {
      ...{
        date: params.date,
        line_id: params.lineId,
        h_start: params.h_start,
        h_end: params.h_end,
      },
    })
    .then(({ data }) => data);
  return response;
};

export const saveAffectedVehicles = async (data: any): Promise<any> => {
  const url = "trips/voyageUpdate";
  const response = await httpClient.post(url, data).then(({ data }) => data);
  return response;
};

export enum RootOrderTypeModel {
  P = "P",
  N = "N",
}

export interface StationModel {
  id_str: string;
  name: string;
}

export interface OrderModel {
  id: number;
  reference: string;
  departure_time: string;
  arriving_time: string;
  departure: StationModel;
  arriving: StationModel;
  pax: PaxModel;
  type: string;
}

export interface RootOrderModel {
  orders: OrderModel[];
  trip: string;
  type: RootOrderTypeModel;
  vehicle_type_id: number | null;
}

export interface Original {
  reference: string;
  id: number;
  pax: PaxModel;
}

export interface PaxModel {
  babies: number;
  childs: number;
  adults: number;
  total: number;
}

export interface VoyageOrderModel {
  arrivingTime?: string;
  departureTime?: string;
  id: number;
  order_id: number;
  order_type: string;
  original: Original;
  pax: PaxModel;
  // type: RootOrderTypeModel;
}

export interface RootVehicleModel {
  id: number;
  uuid: string | undefined;
  id_str: string;
  vehicle_type_id: number;
  status: string;
  date: string;
  pax: PaxModel;
  vehicleType: VehicleTypeModel;
  orders: VoyageOrderModel[];
  trip: string;
}

export interface ResponseModel {
  orders: RootOrderModel[];
  vehicles: RootVehicleModel[];
}
